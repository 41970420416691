import React, { useEffect, useState, useRef } from "react"
import Layout from "../components/layout"

export default function Myths() {
  const [containerWidth, setCW] = useState(400)
  const location={
    href:"https://covidtracker.wjatt.com/myths"
}
  const sectionRef = useRef(null)
  const data = [
    {
      id: 1,
      photo:
        "https://www.who.int/images/default-source/health-topics/coronavirus/myth-busters/web-mythbusters/mb-sun-exposure.tmb-1200v.jpg?sfvrsn=658ce588_1",
    },
    {
      id: 2,
      photo:
        "https://www.who.int/images/default-source/health-topics/coronavirus/myth-busters/web-mythbusters/mb-recovery.tmb-1200v.jpg?sfvrsn=1404cfd0_1",
    },
    {
      id: 3,
      photo:
        "https://www.who.int/images/default-source/health-topics/coronavirus/myth-busters/web-mythbusters/mb-breathing-exercice.tmb-1200v.jpg?sfvrsn=db06f4a9_1",
    },
    {
      id: 4,
      photo:
        "https://www.who.int/images/default-source/health-topics/coronavirus/myth-busters/web-mythbusters/mb-alcohol.tmb-1200v.jpg?sfvrsn=19ea13fb_1",
    },
    {
      id: 5,
      photo:
        "https://www.who.int/images/default-source/health-topics/coronavirus/myth-busters/52.tmb-1200v.png?sfvrsn=862374e_1",
    },
    {
      id: 6,
      photo:
        "https://www.who.int/images/default-source/health-topics/coronavirus/myth-busters/web-mythbusters/mb-cold-snow.tmb-1200v.png?sfvrsn=1e557ba_1",
    },
    {
      id: 7,
      photo:
        "https://www.who.int/images/default-source/health-topics/coronavirus/myth-busters/web-mythbusters/mb-hot-bath.tmb-1200v.png?sfvrsn=f1ebbc_1",
    },
    {
      id: 8,
      photo:
        "https://www.who.int/images/default-source/health-topics/coronavirus/myth-busters/web-mythbusters/mb-mosquito-bite.tmb-1200v.png?sfvrsn=a1d90f6_1",
    },
    {
      id: 9,
      photo:
        "https://www.who.int/images/default-source/health-topics/coronavirus/myth-busters/web-mythbusters/mythbusters-27.tmb-1200v.png?sfvrsn=d17bc6bb_1",
    },
    {
      id: 10,
      photo:
        "https://www.who.int/images/default-source/health-topics/coronavirus/myth-busters/mythbusters-31.tmb-1200v.png?sfvrsn=e5989655_1",
    },
    {
      id: 11,
      photo:
        "https://www.who.int/images/default-source/health-topics/coronavirus/myth-busters/web-mythbusters/mythbusters-25.tmb-1200v.png?sfvrsn=d3bf829c_2",
    },
    {
      id: 12,
      photo:
        "https://www.who.int/images/default-source/health-topics/coronavirus/myth-busters/web-mythbusters/mythbusters-33.tmb-1200v.png?sfvrsn=47bfd0aa_2",
    },
    {
      id: 13,
      photo:
        "https://www.who.int/images/default-source/health-topics/coronavirus/myth-busters/web-mythbusters/11.tmb-1200v.png?sfvrsn=97f2a51e_2",
    },
    {
      id: 14,
      photo:
        "https://www.who.int/images/default-source/health-topics/coronavirus/myth-busters/23.tmb-1200v.png?sfvrsn=c65dad38_3",
    },
    {
      id: 15,
      photo:
        "https://www.who.int/images/default-source/health-topics/coronavirus/myth-busters/19.tmb-1200v.png?sfvrsn=52adfc93_3",
    },
    {
      id: 16,
      photo:
        "https://www.who.int/images/default-source/health-topics/coronavirus/myth-busters/mythbuster-2.tmb-1200v.png?sfvrsn=635d24e5_3",
    },
    {
      id: 17,
      photo:
        "https://www.who.int/images/default-source/health-topics/coronavirus/myth-busters/mythbuster-3.tmb-1200v.png?sfvrsn=10657e42_3",
    },
    {
      id: 18,
      photo:
        "https://www.who.int/images/default-source/health-topics/coronavirus/myth-busters/web-mythbusters/mythbuster-4.tmb-1200v.png?sfvrsn=e163bada_3",
    },
  ]
  useEffect(() => {
    function debounce(func, wait, immediate) {
      var timeout
      return function() {
        var context = this,
          args = arguments
        var later = function() {
          timeout = null
          if (!immediate) func.apply(context, args)
        }
        var callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
      }
    }
    const updateWidth = debounce(() => {
      setCW(sectionRef.current.clientWidth)
    }, 500)
    //setSectionWidth(sectionRef.current.clientWidth)
    updateWidth()
    window.addEventListener("resize", updateWidth)
    return () => {
      window.removeEventListener("resize", updateWidth)
    }
  }, [])
  return (
    <Layout location={location}>
      <section ref={sectionRef}>
        <h3>Myths</h3>
        <p>There are many myths surrounding covid-19. Here are the World Health Orgainisation's answers to some common myths</p>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: containerWidth < 800 ? "1fr" : "1fr 1fr",
            columnGap: "4px",
            rowGap: "4px",
          }}
        >
          {data.map(d => (
            <div key={d.id}>
              <img src={d.photo} alt="" style={{ marginBottom: "0px" }} />
            </div>
          ))}
        </div>
      </section>
    </Layout>
  )
}


